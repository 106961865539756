@use '@angular/material' as mat;
@import '~@angular/material/theming';
// Line added to prevent warnings caused by duplicate classes in different client theme files
mat.$theme-ignore-duplication-warnings: true;


$mat-white: (
  500: white,
  contrast: (
    500: $black-87-opacity
  )
);

$mat-black: (
  500: black,
  contrast: (
    500: white,
  )
);

$mat-eCasedark: (
  50: #ECECEE,
  100: #C5C6CB,
  200: #9EA1A9,
  300: #7D818C,
  400: #5C616F,
  500: #3C4252,
  600: #353A48,
  700: #2D323E,
  800: #262933,
  900: #1E2129,
  A100: #C5C6CB,
  A200: #9EA1A9,
  A400: #5C616F,
  A700: #2D323E,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $white-87-opacity,
    A400: $white-87-opacity,
    A700: $white-87-opacity,
  )
);


.nav-link-container {
  &.selected {
    text-decoration: none;
    color: #F05A23;
    border-bottom: 3px #F05A23 solid;
  }
  &.onHover:hover{
    text-decoration: none !important;
  }
}

.navbar-header {

  .logo {

    .logo-icon {
      width: 180px !important;
      height: auto;
    }

  }
}


#login, #forgot-password, #two-factor-authentication, #register, #adfs-saml2-login{
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  background: url('/../../../../assets/images/backgrounds/irsstBackground.svg') no-repeat !important;
  background-size: auto 80% !important;
  background-position: center center !important;

  #login-intro, #register-intro {

    .logo {
      margin-bottom: 20px !important;
      max-width: 200px !important;
    }
  }

  .title {
    padding-left: 0;
    color: #000000 !important;
    font-size: 28px !important;
  }

  .subtitle {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    width: fit-content !important;
    padding-left: 0;
  }

  .description {
    color: #000000 !important;
    padding-left: 0;
  }
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
  color: #F05A23;
  // border-bottom: 3px #CF0722 solid;

}

/* Styles for the ink bar */
.mat-ink-bar {
  background-color: #F05A23 !important;
}



/*Palettes*/

$primary: mat.define-palette($mat-eCasedark);
$navBackgroundColor: mat.define-palette($mat-white);
$navTextColor: mat.define-palette(mat.$grey-palette, 600);
$navFormTextColor: mat.define-palette(mat.$grey-palette, 600);


$accent: mat.define-palette(mat.$orange-palette, A700, 400, A700);
$accent-lighter: mat.define-palette($accent, 600, 600, 600);
$accent-very-light: mat.define-palette($accent, 50, 50, 50);
$warn: mat.define-palette(mat.$orange-palette);

/*Create the theme object (a Sass map containing all of the palettes).*/
$theme: mat.define-light-theme($primary, $accent, $warn);

$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

.nav .nav-item .nav-link {
  &:hover{
    color: white;
    background-color: mat.get-color-from-palette($accent-lighter) !important;
    .nav-link-icon, .nav-link-badge, .collapse-arrow {
      color: white;
    }
  }
}

.darkMode {
  transition: color 0.5s, background 0.5s;
  .mat-table_filtered_header_cell {
    background-color: rgba(255, 255, 255, 0.12);
  }

  .breadcrumb {
    background-color: #545454 !important;
  }

  .breadcrumb > li + li:before {
    color: white !important;
  }

  .breadcrumb > .active {
    color: white !important;
  }

  .breadcrumb > .isLast {
    color: white !important;
  }

  .row_filter_icon {
    color: white !important;
  }

  .mat-icon {
    color: white !important;
  }

  .mat-toolbar {
    background: #2d2b2b !important;
    color: white !important;
  }

  .toolbar-separator {
    border-right: 1px solid white !important;
    background: white;
  }

  .left-navbar {
    background-color: #424242 !important;
  }

  #main-navigation {
    background-color: #424242;
  }

  #main-table {
    background-color: #424242 !important;
  }

  .content-card {
    background-color: #424242 !important;
  }

  .in-progress-container{
    background-color: #424242;
  }

  .navbar-header {
    background-color: #545454;
  }

  .content-wrapper {
    background-color: #545454;
  }

  .application-portal-quick-access-color {
    color: white;
  }

  main {
    background-color: #545454;
  }

  mat-grid-tile {
    background-color: #424242 !important;
    mat-grid-tile-header {
      background-color: #424242 !important;
    }
  }

  mat-card-title {
    color: white;
  }

  $angular-dark-theme: mat.define-dark-theme(
      (
        color: (
          primary: $primary,
          accent: $accent,
          warn: $warn,
        ),
      )
  );

  $navBackgroundColor: mat.define-palette($mat-white);
  $navTextColor: mat.define-palette($mat-eCasedark);
  $navFormTextColor: mat.define-palette(mat.$grey-palette, 600);
  @include mat.all-component-colors($angular-dark-theme);
}
